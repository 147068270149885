import React from 'react';
import { Link, graphql } from 'gatsby';
import Layout from '../components/layout';
import SEO from '../components/seo';
import Article from '../components/article';

const BlogIndex = ({ data, location }: { data: any; location: any }) => {
  const posts = data.allMarkdownRemark.nodes;

  return (
    <Layout>

      <SEO title="Home" />
      <Article>
        <table className='table table-borderless'>
          {posts.map((post: any) => {
            return (
              <tr key={post.fields.slug}>
                <td className='text-nowrap'><small>{post.frontmatter.date}</small></td>
                <td>
                  <div className='p-1'>
                    {post.frontmatter.tags.includes("featured") ? '💖' : ''}
                    <Link to={post.fields.slug}>{post.frontmatter.title}</Link>
                  </div>
                </td>
              </tr>
            )
          })}

        </table>
      </Article>


    </Layout >
  );
};

export default BlogIndex;

export const pageQuery = graphql`
query {
  site {
    siteMetadata {
      title
    }
  }
  allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
    nodes {
      fields {
        slug
      }
      frontmatter {
        date(formatString: "MMM D, YYYY")
        title
        tags
      }
    }
  }
}
`;
